<template>
  <q-card
    flat
    bordered
    class="column justify-between"
    style="width:300px;height:calc(100vh - 85px)"
  >
    <q-card-section class="q-gutter-md">
      <q-select
        outlined
        v-if="user.is_super_user == '1'"
        v-model="selMusyrif"
        label="Musyrif"
        :options="musyrif"
        @input="
          selected.siswa = null;
          getSiswa();
        "
      />
      <q-select
        outlined
        stack-label
        label="Pilih Siswa"
        :options="option.siswa"
        v-model="selected.siswa"
      ></q-select>
      <q-select
        outlined
        stack-label
        label="Tipe"
        :options="option.tipe"
        v-model="selected.tipe"
        @input="getMinggu"
      ></q-select>
      <q-select
        outlined
        stack-label
        label="Pilih Minggu"
        :disable="selected.tipe == 'Nilai_Akhir' || selected.tipe == null"
        :options="option.minggu"
        v-model="selected.minggu"
      ></q-select>
    </q-card-section>
    <q-card-actions class="q-pa-none">
      <q-btn
        unelevated
        class="full-width no-border-radius"
        color="primary"
        label="next"
        :disable="
          selected.siswa == null ||
            (selected.minggu == null && selected.tipe == 'Nilai_Pekan') ||
            selected.tipe == null
        "
        @click="emiting"
      ></q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      user: {},
      musyrif: [],
      selMusyrif: {
        value: null,
      },

      option: {
        siswa: [],
        tipe: ["Nilai_Pekan", "Nilai_Akhir"],
        minggu: [],
      },
      selected: {
        siswa: null,
        tipe: null,
        minggu: null,
        musyrif: null,
      },
      id_musyrif: 1,
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.getSiswa();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    emiting() {
      let nama = "";
      if (this.user.is_super_user == "1") {
        nama = this.selMusyrif.label;
      } else {
        nama = this.user.nama;
      }
      this.selected.musyrif = nama;
      this.$emit("next", this.selected);
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/riwayat_absen/getsantri/${this.selMusyrif.value}`
      );
      this.option.siswa = resp.data;
      this.$q.loading.hide();
    },
    async getMapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(`/penilaian/input_nilai/getmapel/${this.id_guru}`)
        .then((resp) => {
          this.option.mapel = resp.data;
        });
      this.$q.loading.hide();
    },
    async getMinggu() {
      if (this.selected.tipe == "Nilai_Akhir") return;
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/input_nilai/getpekan/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`
      );
      console.log(resp.data);
      resp.data.forEach((item, i) => {
        item.label = `${i + 1} (${moment(item.awal).format(
          "DD MMM"
        )} - ${moment(item.akhir).format("DD MMM")})`;
      });
      this.option.minggu = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>
