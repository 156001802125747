<template>
  <q-card flat bordered style="width: 300px; ">
    <q-card-section class="row justify-between items-center q-py-none">
      <q-btn flat dense label="back" color="primary" @click="back"></q-btn>
    </q-card-section>
    <q-separator></q-separator>

    <q-markup-table dense separator="none" class="q-mt-lg">
      <tbody>
        <tr>
          <td>Siswa</td>
          <td>:</td>
          <td>{{ siswa.siswa.label }}</td>
        </tr>
        <tr>
          <td>Aspek</td>
          <td>:</td>
          <td>{{ aspek }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <q-markup-table
      dense
      flat
      wrap-cells
      separator="cell"
      class="stickyTable no-border-radius bg-grey-4"
      style="height: calc(100vh - 320px)"
    >
      <thead>
        <tr>
          <th>No</th>
          <th>Sub-Aspek</th>
          <th v-if="siswa.tipe == 'Nilai_Pekan'">Target</th>
          <th v-else-if="siswa.tipe == 'Nilai_Akhir'">Rerata Nilai</th>
          <th v-if="siswa.tipe == 'Nilai_Pekan'">
            Nilai
          </th>
          <th v-else-if="siswa.tipe == 'Nilai_Akhir'">
            Nilai Musyrif
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(val, i) in dataNilai[page]" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ val.nama_sub }}</td>
          <td v-if="siswa.tipe == 'Nilai_Pekan'">{{ val.target }}</td>
          <td v-else-if="siswa.tipe == 'Nilai_Akhir'">
            {{ val.rerata | number("0.0") }}
          </td>
          <template>
            <q-td>
              {{ formatNilai(val.nilai) }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input 
                  bottom-slots
                  type="number"
                  v-model="val.nilai"
                  dense
                  autofocus
                  @input="isChanged = true"
                >
                <template v-slot:hint>
                  <a v-if="val.nilai > val.target">kertercapaian sudah melewati target</a>
                </template>
                </q-input>
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
      </tbody>
    </q-markup-table>
    <q-card-section class="row justify-between bg-grey-3">
      <q-btn
        outline
        :disable="page == 0"
        color="primary"
        label="<"
        @click="page = page - 1"
      ></q-btn>
      <a>{{ page + 1 }} / {{ totalPage }}</a>
      <q-btn
        outline
        :disable="page == dataNilai.length - 1"
        color="primary"
        label=">"
        @click="page = page + 1"
      ></q-btn>
    </q-card-section>
    <q-card-section class="q-pa-none q-ma-none">
      <q-btn
        unelevated
        class="full-width no-border-radius"
        color="negative"
        label="simpan"
        :disable="!isChanged"
        @click="simpan"
      ></q-btn>
    </q-card-section>
    <q-dialog v-model="toBack">
      <q-card>
        <q-card-section>
          Apa Anda yakin ingin meninggalkan halaman ini? perubahan yg anda
          lakukan tidak akan tersimpan
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="YA"
            @click="$emit('back')"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
export default {
  props: ["siswa"],
  data() {
    return {
      toBack: false,
      isChanged: false,
      page: 0,
      totalPage: 0,
      dataNilai: [],
      nilai: null,
    };
  },
  computed: {
    aspek() {
      if (this.dataNilai.length < 1) return "-";
      else return this.dataNilai[this.page][0].kelompok;
    },
  },
  async mounted() {
    await this.getAspek();
  },
  methods: {
    back() {
      if (this.isChanged) {
        this.toBack = true;
      } else {
        this.$emit("back");
      }
    },
    async simpan() {
      if (this.siswa.tipe == "Nilai_Pekan") {
        await this.$http.put(
          `/penilaian/input_nilai/simpan/${this.siswa.minggu}`,
          this.dataNilai
        );
        this.$q.notify({
          message: "Nilai Berhasil Disimpan",
          color: "positive",
        });
      } else if (this.siswa.tipe == "Nilai_Akhir") {
        await this.$http.put(`/penilaian/input_nilai/simpan`, this.dataNilai);
        this.$q.notify({
          message: "Nilai Berhasil Disimpan",
          color: "positive",
        });
      }
      this.getAspek();
      this.isChanged = false;
    },
    formatNilai(nilai) {
      if (nilai == null) return "-";
      else return nilai;
    },
    async getAspek() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = null;
      if (this.siswa.tipe == "Nilai_Pekan") {
        resp = await this.$http.get(
          `/penilaian/input_nilai/getNilai/${
            this.siswa.siswa.value
          }/${localStorage.getItem("jenjang")}/${this.siswa.minggu.value}`
        );
      } else if (this.siswa.tipe == "Nilai_Akhir") {
        resp = await this.$http.get(
          `/penilaian/input_nilai/getNilai/${
            this.siswa.siswa.value
          }/${localStorage.getItem("jenjang")}`
        );
      }
      this.dataNilai = resp.data;
      this.totalPage = this.dataNilai.length;
      this.$q.loading.hide();
    },
  },
};
</script>
