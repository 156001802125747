<template>
  <div class="row justify-center bg-grey-3">
    <PilihSiswa v-if="step == 1" @next="showInputTugas"></PilihSiswa>
    <InputAspek
      v-if="step == 2"
      :siswa="step1data"
      @back="step = 1"
    ></InputAspek>
  </div>
</template>

<script>
import PilihSiswa from "@/views/InputNilai/PilihSiswa";
import InputAspek from "@/views/InputNilai/InputAspek";
export default {
  components: { PilihSiswa, InputAspek },
  data() {
    return {
      step: 1,
      step1data: {},
    };
  },
  mounted() {},
  methods: {
    async showInputTugas(selected) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.get(
        `/penilaian/input_nilai/cekSiswa/${
          selected.siswa.value
        }/${localStorage.getItem("jenjang")}/${localStorage.getItem(
          "id_tahun_ajaran"
        )}/${selected.musyrif}`
      );
      if (selected.tipe == "Nilai_Pekan") {
        await this.$http.get(
          `/penilaian/input_nilai/ceknilai/${selected.siswa.value}/${selected.minggu.value}`
        );
      }
      this.step1data = selected;
      this.step = 2;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
